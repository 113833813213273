import React,  { Fragment } from "react"
import { navigate } from "gatsby"

import { useAppData } from '../providers/appData'

const PrivatePage = ({ children }) => {
    const [appDataState] = useAppData();

    if (appDataState.isLoading || appDataState.isLogged) {
      return <Fragment>
        {children}
      </Fragment>
    } else {
      navigate('/');
      return null;
    }
}

export default PrivatePage 