import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import PrivatePage from '../components/privatePage'
import SEO from "../components/seo"


const Container = styled.div`
  h1 {
    margin: auto 0;
    padding-top: 0.5em;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-video {
    background-color: black;
    margin: 30px 0;
    flex-wrap: wrap;
  }

  #video {
    width: 70%;
    height: 600px;
    box-sizing: border-box;
    padding: 0 5px;
    min-width: 500px;
    flex-grow: 1;
  }

  #chat {
    min-width: 300px;
    width: 30%;
    height: 590px;
    padding: 0 5px;
    box-sizing: border-box;
    margin: auto auto;
    flex-grow: 1;
  }
`

const SessionsPage = () => {

  return (

      <Layout>
    <PrivatePage>
        <SEO title="General Sessions" />
    
        <Container>
          <div className="header">
            <h1>General Sessions</h1>
          </div>
          <div className="main-video">
            <iframe
              title="session"
              id="video"
              src="https://vimeo.com/event/257480/embed/c075a4265c"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
            ></iframe>

            <iframe
              title="chat"
              id="chat"
              src="https://vimeo.com/event/257480/chat/c075a4265c"
              width="400"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </Container>
    </PrivatePage>
      </Layout>

  )
}

export default SessionsPage
